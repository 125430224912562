<template>
    <main id="liste" v-cloak>
        <!-- A changer par le nom du tiers sur lequel on effectue les opérations de compta -->
        <HeaderTab :title="$t('compta.facturation')" :swiper_tabs="swiper_tabs" />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <router-view></router-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">

    import Navigation from "@/mixins/Navigation.js";
	import Cards from '@/mixins/Cards.js'

	export default {
		name: "Invoice",
		mixins: [Navigation, Cards],
		data () {
			return {
                param_tab: '',
                actual_tab: 'invoiceList',
                isTrotteur: false
			}
		},

		mounted() {
            this.param_tab = this.$route.params.actual_tab
            this.init_component()
		},

        computed: {
            swiper_tabs() {
                return [{
                        id: 'a_valider',
                        label: 'invoice.titre_a_valider',
                        active: false,
                        href : 'invoiceAValider',
                    }, {
                        id: 'control_properties',
                        label: 'invoice.control_properties',
                        active: false,
                        href : 'controlProperties'
                    }, {
                        id: 'article_monte',
                        label: 'monte.article_monte',
                        active: false,
                        hidden: !this.hasSaillieAccess,
                        dropdown: [{
                                label: 'monte.article_valider_signature',
                                href : 'aValiderSignature',
                                hidden: false,
                                active: false,
                            },{
                                label: 'monte.article_valider_saillie',
                                href : 'aValiderSaillie',
                                hidden: false,
                                active: false,
                            },{
                                label: 'monte.article_valider_octobre',
                                href : 'aValiderOctobre',
                                hidden: false,
                                active: false,
                            },{
                                label: 'monte.article_valider_poulain',
                                href: 'aValiderPoulain',
                                hidden: false,
                                active: false,
                            }],
                    }, {
                        id: 'article_course',
                        label: 'invoice.titre_article_course',
                        active: false,
                        hidden: !this.isTrotteur,
                        href : 'articleCourse'
                    }, {
                        id: 'm_bon',
                        label: 'invoice.titre_bon_a_facturer',
                        active: false,
                        href : 'invoiceBons'
                    }, {
                        id: 'm_facture',
                        label: 'invoice.titre_facture',
                        active: false,
                        href : 'invoiceList'
                    }, {
                        id: 'm_impayes',
                        label: 'invoice.titre_impayes',
                        active: false,
                        href : 'impayesList'
                    }, {
                        id: 'm_list_tiers_impayes',
                        label: 'invoice.titre_tiers_impayes',
                        active: false,
                        href : 'tiersImpayesList'
                    }
                ]
            },

            hasSaillieAccess() {
                return (this.$store.state.userAccess.hasSaillieAccess)
            }
        },

		methods: {
            async init_component() {
                // CHECK ICI SI this.$route.params.tiers_id
                // est valide et tout et tout

                // On regarde si la carte "Activité principale" a été répondue par "Trotteur" pour afficher ou non l'onglet Facturation > Article de course à valider
                const cards = await this.getConfig('cards')
                this.isTrotteur = (cards['main_activity'] === 1)
            }
		},
		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
		}
	}
</script>
